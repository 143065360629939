/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DoctorDegree
 */
export interface DoctorDegree {
    /**
     * 
     * @type {string}
     * @memberof DoctorDegree
     */
    full?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorDegree
     */
    _short?: string;
}

/**
 * Check if a given object implements the DoctorDegree interface.
 */
export function instanceOfDoctorDegree(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DoctorDegreeFromJSON(json: any): DoctorDegree {
    return DoctorDegreeFromJSONTyped(json, false);
}

export function DoctorDegreeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DoctorDegree {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'full': !exists(json, 'full') ? undefined : json['full'],
        '_short': !exists(json, '_short') ? undefined : json['_short'],
    };
}

export function DoctorDegreeToJSON(value?: DoctorDegree | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'full': value.full,
        'short': value._short,
    };
}

