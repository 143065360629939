import { default as _404WEi6t8tC6dMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/404.vue?macro=true";
import { default as _500LqFYexnTeZMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/500.vue?macro=true";
import { default as forgot_45password6HOkTd7NgSMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/auth/forgot-password.vue?macro=true";
import { default as register_45with_45otpKbCv9EjRa5Meta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/auth/register-with-otp.vue?macro=true";
import { default as reset_45passwordtGWfIlULE3Meta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/auth/reset-password.vue?macro=true";
import { default as sign_45iniYnva006wQMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/auth/sign-in.vue?macro=true";
import { default as authu1ivGNoLjWMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/auth.vue?macro=true";
import { default as _91id_93K0dmuv5DtAMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/booking-v2/[id].vue?macro=true";
import { default as indexpY6GWGrJq6Meta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/booking-v2/index.vue?macro=true";
import { default as _91id_93W6w30WfIlfMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/booking/[id].vue?macro=true";
import { default as index18Nq1hz9Q3Meta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/booking/index.vue?macro=true";
import { default as _91clinicId_93QycxY9aQmIMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/clinic/[clinicId].vue?macro=true";
import { default as indexu0LU8wp8tZMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/clinic/index.vue?macro=true";
import { default as dashboard2fo10rEV73Meta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/dashboard.vue?macro=true";
import { default as _91id_935l0P1VS7XTMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/doctor/[id].vue?macro=true";
import { default as indexjODCeqxZFNMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/index.vue?macro=true";
import { default as maintenancebo6z3h0zX3Meta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/maintenance.vue?macro=true";
import { default as _91id_93Wsd7Q0tXwNMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/question/[id].vue?macro=true";
import { default as addgSj1A7KdnnMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/question/add.vue?macro=true";
import { default as indexopHBJybP2nMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/question/index.vue?macro=true";
import { default as _91slug_93oxin6YeJnUMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/tin-tuc/[slug].vue?macro=true";
import { default as indexax9R7l4XSQMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/tin-tuc/index.vue?macro=true";
import { default as _91id_931OMsf81tvHMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/wv/question/[id].vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    meta: _404WEi6t8tC6dMeta || {},
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "500",
    path: "/500",
    meta: _500LqFYexnTeZMeta || {},
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/500.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    meta: authu1ivGNoLjWMeta || {},
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/auth.vue").then(m => m.default || m),
    children: [
  {
    name: "auth-forgot-password",
    path: "forgot-password",
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-register-with-otp",
    path: "register-with-otp",
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/auth/register-with-otp.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password",
    path: "reset-password",
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-sign-in",
    path: "sign-in",
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/auth/sign-in.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "booking-v2-id",
    path: "/booking-v2/:id()",
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/booking-v2/[id].vue").then(m => m.default || m)
  },
  {
    name: "booking-v2",
    path: "/booking-v2",
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/booking-v2/index.vue").then(m => m.default || m)
  },
  {
    name: "booking-id",
    path: "/booking/:id()",
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/booking/[id].vue").then(m => m.default || m)
  },
  {
    name: "booking",
    path: "/booking",
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/booking/index.vue").then(m => m.default || m)
  },
  {
    name: "clinic-clinicId",
    path: "/clinic/:clinicId()",
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/clinic/[clinicId].vue").then(m => m.default || m)
  },
  {
    name: "clinic",
    path: "/clinic",
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/clinic/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "doctor-id",
    path: "/doctor/:id()",
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/doctor/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexjODCeqxZFNMeta || {},
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenancebo6z3h0zX3Meta || {},
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "question-id",
    path: "/question/:id()",
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/question/[id].vue").then(m => m.default || m)
  },
  {
    name: "question-add",
    path: "/question/add",
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/question/add.vue").then(m => m.default || m)
  },
  {
    name: "question",
    path: "/question",
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/question/index.vue").then(m => m.default || m)
  },
  {
    name: "tin-tuc-slug",
    path: "/tin-tuc/:slug()",
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/tin-tuc/[slug].vue").then(m => m.default || m)
  },
  {
    name: "tin-tuc",
    path: "/tin-tuc",
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/tin-tuc/index.vue").then(m => m.default || m)
  },
  {
    name: "wv-question-id",
    path: "/wv/question/:id()",
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/wv/question/[id].vue").then(m => m.default || m)
  }
]